import {EnvelopeIcon, PaperAirplaneIcon, PhoneIcon} from "@heroicons/react/24/solid";
import emailjs from '@emailjs/browser';
import {useState} from "react";
const Contact = () => {
    const [fromEmail, setFromEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const sendEmail = (e) => {

        if (fromEmail === "" || subject === "" || message === "") {
            return;
        }

        setIsLoading(true);
        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        let params = {
            from_email: fromEmail,
            subject: subject,
            message: message,
        }
        emailjs.send('service_ycbgwas', 'template_i55o2ml', params, 'rBTHaztM5zrZENQ8b')
            .then((result) => {
                setIsLoading(false);
                setFromEmail("");
                setSubject("");
                setMessage("");
            }, (error) => {
                console.log(error.text)
            });

    };

    const contacts = [
        {
            icon: <PhoneIcon className="w-16 h-16 lg:w-6 lg:h-6 fill-emerald-400"/>,
            text: "Phone Number: +39 346 8060920",
        },
        {
            icon: <EnvelopeIcon className="w-16 h-16 lg:w-6 lg:h-6 fill-emerald-400"/>,
            text: "Email: lorenzo.spataro09@gmail.com",
        },
        {
            icon: <PaperAirplaneIcon className="w-16 h-16 -rotate-45 lg:w-6 lg:h-6 fill-emerald-400"/>,
            text: "Telegram: @loryspat",
        }
    ]

    return (
        <div id="contact" className="pt-60 lg:pt-40 w-full lg:min-h-0 min-h-screen">
            <div className="text-white font-extrabold text-8xl lg:text-5xl text-center">
                CONTACT ME
            </div>
            <div className="grid grid-cols-2 text-white gap-14 lg:gap-7 mt-32 lg:mt-16">
                <div
                    className="col-span-2 lg:col-span-1 flex flex-col justify-end">
                    <div className="flex flex-col space-y-8">
                        <div className="font-bold text-4xl lg:text-xl">
                            Contact Information
                        </div>
                        <div className="text-3xl lg:text-base">
                            Here are some ways to get in touch with me. I am available on Telegram, WhatsApp, Email and
                            Phone.
                        </div>
                    </div>
                    <div className="flex flex-col space-y-8 pt-4 text-4xl lg:text-base">
                        {
                            contacts.map((item, index) => {
                                return (
                                    <div key={index} className="flex border rounded-3xl p-8 lg:rounded-md border-gray-800  items-center space-x-4 lg:space-x-2 text-4xl lg:text-base font-bold">
                                        {item.icon}
                                        <div>
                                            {item.text}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div
                    className="col-span-2 lg:col-span-1">
                    <form onSubmit={sendEmail}>
                        <div className="flex flex-col space-y-10 lg:space-y-5 border rounded-3xl lg:rounded-md border-gray-800 p-12 lg:p-6 text-4xl lg:text-base ">
                            <div className="flex flex-col space-y-5 lg:space-y-1">
                                <div className="font-bold">
                                    Email
                                </div>
                                <input
                                    className="focus:border-aqua text-3xl bg-gray-700 border border-green-400 text-gray-900 lg:text-sm rounded-xl lg:rounded-lg block w-full p-7 lg:p-2.5 focus:outline-none dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Insert your email"
                                    type="email"
                                    onChange={(e) => setFromEmail(e.target.value)} required
                                    value={fromEmail}
                                />
                            </div>
                            <div className="flex flex-col space-y-5 lg:space-y-1">
                                <div className="font-bold">
                                    Subject
                                </div>
                                <input
                                    onChange={(e) => setSubject(e.target.value)}
                                    className="focus:border-aqua focus:outline-none  bg-gray-700 border border-gray-300 text-gray-900 text-3xl lg:text-sm rounded-xl lg:rounded-lg focus:outline-aqua block w-full p-7 lg:p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                                    placeholder="Insert subject"
                                    value={subject}
                                    required/>
                            </div>
                            <div className="flex flex-col space-y-5 lg:space-y-1">
                                <div className="font-bold">
                                    Message
                                </div>
                                <textarea id="message" rows="6"
                                          className="focus:border-aqua focus:outline-none block p-7 lg:p-2.5 w-full text-3xl lg:text-sm text-gray-900 bg-gray-800 rounded-xl lg:rounded-lg focus:outline-aqua shadow-sm border border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                          placeholder="Leave a message..." onChange={(e) => setMessage(e.target.value)} value={message} required/>
                            </div>
                            {!isLoading ?
                                <button type="submit" className="border bg-aqua rounded-2xl lg:rounded-lg font-bold text-slate-900 p-8 lg:p-2 w-fit cursor-pointer border-gray-700 text-center">Send message</button> :
                                <div className="border bg-aqua rounded-2xl lg:rounded-lg font-bold text-slate-900 p-8 lg:p-2 w-fit border-gray-700 text-center">Sending...</div>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;