import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import {useState} from "react";
import Resume from "./Resume";
import Contact from "./Contact";


const Main = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div
            className="w-full h-auto flex flex-col space-y-24 bg-gradient-to-r from-gray-900 to-slate-900  ">
            <Navbar onMenuStatusChange={() => setIsMenuOpen(!isMenuOpen)}/>
            {!isMenuOpen && <div className="flex flex-col items-center px-10 md:px-20 xl:px-60 ">
                <Home/>
                <About/>
                <Resume/>
                <Contact/>
            </div>}
        </div>

    );
};

export default Main;


