import img from '../images/img.png';
import {ArrowDownIcon} from "@heroicons/react/24/solid";

const Home = () => {
    const icons = [
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 lg:w-6 lg:h-6 stroke-gray-500"
                       viewBox="0 0 24 24" strokeWidth="2" fill="none"
                       strokeLinecap="round" strokeLinejoin="round">
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
            </svg>,
            url: "https://www.instagram.com/lorenzoo_spataro",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                       className="w-16 h-16 lg:w-6 lg:h-6 stroke-gray-500"
                       strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
            </svg>,
            url: "https://www.linkedin.com/in/lorenzo-spataro-b0690117a",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 lg:w-6 lg:h-6 stroke-gray-500"
                       viewBox="0 0 24 24"
                       fill="none"
                       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path
                    d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
            </svg>,
            url: "https://www.github.com/lorenzosp01"
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 lg:w-6 lg:h-6 stroke-gray-500"
                       viewBox="0 0 24 24"
                       fill="none"
                       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path
                    d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
            </svg>,
            url: ""
        }
    ]

    return (
        <div className="w-full h-auto" id="home">
            <div
                className="flex flex-col space-y-20 lg:space-y-8 items-center text-white mx-6 font-bold lg:mt-40 mt-64">
                <img className="w-64 h-64 lg:w-32 lg:h-32 rounded-full" src={img} alt=""/>
                <div className="text-6xl lg:text-2xl text-center font-extrabold">
                    Hi there, I am Lorenzo Spataro
                </div>
                <div className="text-center text-4xl text-gray-400  lg:text-lg">
                    I am a current student pursuing a bachelor degree in Computer Science at Sapienza University of Rome, and I also work part-time to support myself financially.
                    I have a strong passion for technology and computing, and I am constantly seeking to expand my knowledge and skills in this field through my academic studies and work experiences.
                </div>
                <div className="flex justify-between space-x-8">
                    {icons.map((item, index) => (
                        <div key={index}
                             className="border-2 lg:border border-gray-700 p-8 lg:p-2 lg:rounded-md rounded-xl cursor-pointer ">
                            <a href={item.url} target="_blank" rel="noreferrer">
                                {item.icon}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-center space-x-3 items-center font-bold my-48 lg:my-32">
                <a href={"#about"} className="text-5xl lg:text-2xl text-aqua">Scroll down</a>
                <ArrowDownIcon className="animate-bounce duration-100 w-12 h-12 lg:w-6 lg:h-6 fill-aqua"/>
            </div>
        </div>
    );
}

export default Home;