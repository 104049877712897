import {useState} from "react";
import CV from "../docs/CV.pdf";

const Navbar = (props) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const MobileMenu = () => {
        return (
            <div className="w-full min-h-screen fixed top-0 flex flex-col font-bold text-aqua">
                <div className="w-full flex justify-end fixed pt-16 px-32 ">
                    <svg className="w-20 h-20 lg:w-14 h-14" aria-hidden="true" fill="currentColor" onClick={() => {
                        setIsMenuOpen(!isMenuOpen)
                        props.onMenuStatusChange()
                    }}
                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        />
                    </svg>
                </div>
                <div className="h-full h-screen flex flex-col items-center">
                    <div className="flex flex-col m-auto items-center text-5xl lg:text-3xl space-y-20">
                        <a onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                            props.onMenuStatusChange()
                        }} href="#home">
                            01. <span className="text-white">HOME</span>
                        </a>
                        <a onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                            props.onMenuStatusChange()
                        }} href="#about">
                            02. <span className="text-white">ABOUT</span>
                        </a>
                        <a onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                            props.onMenuStatusChange()
                        }} href="#resume">
                            03. <span className="text-white">RESUME</span>
                        </a>
                        <a onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                            props.onMenuStatusChange()
                        }} href="#contact">
                            04. <span className="text-white">CONTACT</span>
                        </a>

                        <button
                            className="bg-emerald-300 hover:bg-emerald-400 text-slate-900 text-5xl lg:text-3xl font-bold py-2 px-4 rounded-md mt-4">
                            DOWNLOAD CV
                        </button>
                    </div>
                </div>
            </div>

        )
    }

    return (
        isMenuOpen ? <div className="w-full min-h-screen"><MobileMenu/></div> :
            <div className="text-aqua font-bold">
                <div
                    className="flex w-full z-40 fixed top-0 left-0 right-0 justify-between px-20 xl:px-60 items-center backdrop-blur-md bg-slate-900 bg-opacity-70 border-gray-700 border-b shadow-md">
                    <div className="my-6 text-6xl lg:text-4xl text-center font-extrabold">
                        SPATARO<span className="text-white">.</span>
                    </div>
                    <div className="my-6 lg:flex lg:flex-row lg:space-x-7 hidden ">
                        <a href="#home" className="flex items-center">
                            01. <span className="text-white">HOME</span>
                        </a>
                        <a href="#about" className="flex items-center">
                            02. <span className="text-white">ABOUT</span>
                        </a>
                        <a href="#resume" className="flex items-center">
                            03. <span className="text-white">RESUME</span>
                        </a>
                        <a href="#contact" className="flex items-center">
                            04. <span className="text-white">CONTACT</span>
                        </a>
                    </div>
                    <div className="my-6 lg:hidden">
                        <button onClick={() => {
                            setIsMenuOpen(!isMenuOpen)
                            props.onMenuStatusChange()
                        }}>
                            <svg className="w-20 h-20 lg:w-14 h-14" aria-hidden="true" fill="currentColor"
                                 viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                    <div
                        className="cursor-pointer lg:flex lg:flex-row  justify-between gap-3 bg-emerald-300 text-slate-900 px-2 py-2 my-6 rounded-md items-center hidden">
                        <a href={CV} download="CV">Curriculum</a>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" className=" w-10 h-10 lg:w-5 lg:h-5" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </div>
                </div>
            </div>
    )
}

export default Navbar