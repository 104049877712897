import {BookOpenIcon, BriefcaseIcon} from "@heroicons/react/24/solid";

const TimeLine = ({data}) => (
    <ol className="relative z-20 border-l border-gray-200 dark:border-gray-700">
        {data.map((item, index) => (
            <li className="mb-10 ml-4">
                <div
                    className="absolute w-6 h-6 lg:w-4 lg:h-4 bg-gray-700 rounded-full mt-1 lg:mt-1.5 -left-3 lg:-left-2 "></div>
                <div
                    className="absolute w-3 h-3 lg:w-2 lg:h-2 bg-aqua rounded-full mt-2.5 mx-1.5 lg:mt-2.5 lg:mx-1 -left-3 lg:-left-2"></div>
                <time className="text-4xl lg:text-lg font-normal leading-none text-gray-400 dark:text-gray-500">{item.date}
                </time>
                <h3 className="my-3 text-5xl lg:my-0 lg:text-xl font-semibold text-gray-900 dark:text-white">{item.title}</h3>
                <p className="mb-4 text-4xl lg:text-lg font-normal text-gray-500 dark:text-gray-400">{item.desc}</p>
            </li>
        ))}
    </ol>
)
const Resume = () => {

    const experiences = [
        {
            date: "September 2022 - Present",
            title: "Fondazione CNI",
            desc: "Part-time: Fullstack developer",
        },
        {
            date: "Summer 2022",
            title: "Taporty",
            desc: "Part-time rider: Food & beverage delivery",
        },
        {
            date: "Summer 2021",
            title: "Ciupito srl",
            desc: "Waiter in a pub",
        },
    ]
    
    const educations = [
        {
            date: "September 2020 - Present",
            title: "Sapienza University of Rome",
            desc: "Computer Science bachelor's degree",
        },
        {
            date: "October 2018 - June 2019",
            title: "Pearson Test of English",
            desc: "PTE Academic certificate with B2 level"
        },
        {
            date: "September 2015 - June 2020",
            title: "ITIS \"G. Marconi\" Latina",
            desc: "Computer Science and Engineering diploma",
        }
    ]
    return (
        <div id="resume" className="pt-60 w-full flex flex-col space-y-40 lg:space-y-14 lg:min-h-0 lg:pt-36 min-h-screen">
            <div className="text-white font-extrabold text-8xl lg:text-5xl text-center">
                RESUME
            </div>
            <div className="grid grid-cols-2 text-white font-bold items-center gap-36 lg:gap-7 mt-16">
                <div
                    className="col-span-2 lg:col-span-1 flex flex-col space-y-5">
                    <div className="flex items-center gap-2 text-5xl lg:text-2xl">
                        <BookOpenIcon className="w-16 h-16 fill-aqua lg:w-8 lg:h-8 mr-2"/>
                        <div>
                            Education
                        </div>
                    </div>
                    <div className="px-10">
                        <TimeLine data={educations}/>
                    </div>
                </div>
                <div
                    className="col-span-2 lg:col-span-1 flex flex-col lg:mt-0 space-y-5">
                    <div className="flex items-center gap-2 text-5xl lg:text-2xl">
                        <BriefcaseIcon className="w-16 h-16 fill-aqua lg:w-8 lg:h-8 mr-2"/>
                        <div>
                            Experience
                        </div>
                    </div>
                    <div className="px-10">
                        <TimeLine data={experiences}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume