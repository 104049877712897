import {
    BuildingStorefrontIcon,
    GlobeEuropeAfricaIcon,
    LanguageIcon, UserCircleIcon
} from "@heroicons/react/24/solid";

import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CV from "../docs/CV.pdf";

const Skill = ({index, title, percentage}) => (
    <div key={index}>
        <div className="flex justify-between my-4 text-4xl lg:my-2 lg:text-xl font-bold">
            <span className>{title}</span>
            <span className="text-emerald-400 ">{percentage}</span>
        </div>
        <div className="w-full bg-emerald-800 rounded-full h-8 lg:h-3">
            <div className="bg-aqua h-8 lg:h-3 rounded-full" style={{width: percentage}}></div>
        </div>
    </div>
)

const About = () => {
    const lista = [
        {
            icon: <UserCircleIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "Lorenzo",
            kind: "First name",
        },
        {
            icon: <UserCircleIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "Spataro",
            kind: "Last name",
        },
        {
            icon: <UserCircleIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "21 years old",
            kind: "Age",
        },
        {
            icon: <GlobeEuropeAfricaIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "Italian",
            kind: "Nationality",
        },
        {
            icon: <BuildingStorefrontIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "Sermoneta, Italy",
            kind: "Location",
        },
        {
            icon: <LanguageIcon className="w-10 h-10 lg:w-5 lg:h-5"/>,
            text: "Italian, English",
            kind: "Languages"
        },
    ]

    const skills = [
        {
            title: "React",
            percentage: "70%"
        },
        {
            title: "Vue.JS",
            percentage: "60%"
        },
        {
            title: "HTML",
            percentage: "90%"
        },
        {
            title: "PHP",
            percentage: "30%"
        },
        {
            title: "Python",
            percentage: "80%"
        },
        {
            title: "C/C++",
            percentage: "50%"
        },
        {
            title: "Git",
            percentage: "70%"
        },
        {
            title: "SQL",
            percentage: "70%"
        }

    ]

    const skillsResume = [
        {
            title: "Frontend",
            percentage: "70%",
        },
        {
            title: "Backend",
            percentage: "80%",
        },
        {
            title: "Databases",
            percentage: "70%",
        },
        {
            title: "Markup Languages",
            percentage: "60%",
        }
    ]

    return (
        <div id="about" className="pt-60 w-full lg:min-h-0 lg:pt-24 min-h-screen">
            <div className="text-white font-extrabold text-8xl lg:text-5xl text-center">
                ABOUT ME
            </div>
            <div className="grid grid-cols-2 items-center gap-7 mt-16">
                <div
                    className="col-span-2 lg:col-span-1 overflow-hidden border-8 border-emerald-400 border-opacity-60 rounded-lg">
                    <img src="https://tf-react-bieber.vercel.app/images/about-image.jpg" className="w-full" alt=""/>
                </div>
                <div className="col-span-2 lg:col-span-1">
                    <div className="text-white font-bold text-5xl my-4 lg:my-2 lg:text-2xl">
                        Hi, I am <span className="text-aqua">Lorenzo Spataro</span>
                    </div>
                    <div className="flex flex-col gap-6 lg:gap-2 text-gray-300 my-16 lg:my-0 text-base">
                        {lista.map((item, index) => (
                            <div key={index} className="flex gap-4 text-4xl lg:text-xl items-center ">
                                <div className="text-aqua">
                                    {item.icon}
                                </div>
                                <div>
                                    <span className="font-bold">{item.kind}:</span> {item.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="cursor-pointer text-center py-6 px-4 lg:py-2 lg:px-1 bg-emerald-300 font-bold text-4xl lg:text-lg text-slate-900 my-6 rounded-md items-center w-3/6 lg:w-2/6">
                        <a href={CV} download="CV">DOWNLOAD CV</a>
                    </div>
                </div>
            </div>
            <div className="text-white font-extrabold text-8xl lg:text-5xl text-center mt-20">
                MY SKILLS
            </div>
            <div className="grid grid-cols-4 items-center gap-14 lg:gap-7 mt-16 lg:mt-8 ">
                {skillsResume.map((item, index) => (
                    <div key={index} className="hover:-translate-y-2 duration-300 col-span-4 lg:col-span-1 border rounded-3xl lg:rounded-xl shadow-md bg-gray-800 border-gray-900">
                        <div className="my-8 lg:my-4">
                            <CircularProgressbar key={index} value={item.percentage.replace('%', '')} text={item.percentage} styles={buildStyles(
                                {
                                    textSize: '1.5rem',
                                    textColor: '#72E2AE',
                                    pathColor: '#72E2AE',
                                    trailColor: 'rgb(6 95 70)',

                                })} className="w-64 h-64 lg:w-24 lg:h-24"
                            />
                        </div>
                        <div className="text-center text-4xl lg:text-xl text-white my-8 lg:my-4 font-semibold">
                            {item.title}
                        </div>
                    </div>
                    ))
                }
            </div>
            <div className="grid grid-cols-2 items-center text-white gap-7 mt-16">
                <div className="col-span-2 lg:col-span-1 flex flex-col space-y-4 text-3xl lg:text-xl">
                    {skills.map((skill, index) => (
                        index <= 3 ? <Skill key={index} title={skill.title} percentage={skill.percentage}/> : null
                    ))}
                </div>
                <div className="col-span-2 flex mt-10 lg:mt-0 flex-col space-y-4 lg:col-span-1 text-3xl lg:text-xl">
                    {skills.map((skill, index) => (
                        index > 3 ? <Skill key={index} title={skill.title} percentage={skill.percentage}/> : null
                    ))}
                </div>
            </div>
        </div>
    )
}

export default About;